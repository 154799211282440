import React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import LanguageSelector from '../LanguageSelector';
import LocalizedProperty from '../LocalizedProperty';
import FilterButton from '../FilterButton';
import GlossaryButton from '../GlossaryButton';

const AppBar = () => {
  return (
    <>
      <MuiAppBar position="static" color="primary">
        <Toolbar>
          <Box flexGrow="2">
            <LocalizedProperty propertyName="logo" />
          </Box>
          <Box>
            <FilterButton />
          </Box>
          <Box>
            <GlossaryButton />
          </Box>
          <Box>
            <LanguageSelector />
          </Box>
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

export default AppBar;
