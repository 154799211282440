import React from 'react';
import Select from '@material-ui/core/Select';
import { Remission } from '../../../types';
import FormatRemission from './FormatRemission';
import useStyles from './FormatRemissions.styles';

interface FormatRemissionsProps {
  remissions: Remission[];
}

const FormatRemissions = ({ remissions }: FormatRemissionsProps) => {
  const classes = useStyles();

  const higherRemission = remissions.reduce((acc, remission) => {
    return remission.remissionNumber > acc.remissionNumber ? remission : acc;
  }, remissions[0]);

  return (
    <Select
      className={classes.root}
      defaultValue={higherRemission.remissionNumber}
    >
      {remissions.map(FormatRemission)}
    </Select>
  );
};

export default FormatRemissions;
