import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../Api';
import { AllowedLocale, DetailDocumentResponse } from '../../types';

export const fetchDetailDocument = createAsyncThunk(
  'fetchDetailDocument',
  (id: string, { getState }) =>
    get<DetailDocumentResponse>(
      `/${(getState() as { locale: AllowedLocale }).locale}/documents/${id}`
    ).then((response) => response.data.payload)
);
