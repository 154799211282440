import React, { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import FilterDialog from '../FilterDialog';

const FilterButton = () => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <>
      <Box mr={2}>
        <IconButton
          onClick={() => {
            setOpened(true);
          }}
        >
          <FilterListIcon htmlColor="white" />
        </IconButton>
      </Box>
      <FilterDialog onClose={() => setOpened(false)} opened={opened} />
    </>
  );
};

export default FilterButton;
