import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../Api';
import { AllowedLocale, GetFilterResponse } from '../../types';

export const fetchFilters = createAsyncThunk(
  'fetchFilters',
  (args, { getState }) =>
    get<GetFilterResponse>(
      `/${
        (getState() as { locale: AllowedLocale }).locale
      }/documents/get-filter`
    ).then((response) => response.data.payload.fields)
);
