import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '50px',
      width: '180px',
    },
  })
);

let Logo: any = (props: any) => {
  return (
    <SvgIcon
      className={useStyles().root}
      viewBox="0 0 354 75"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,75.586667)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 2549.75,23.5781 62.46,113.9059 3.92,-134.47228 h 41.39 L 2648.3,210.172 H 2614 L 2549.75,89.0078 2485.59,210.172 h -34.28 L 2441.97,3.01172 h 41.51 l 3.98,134.47228 62.29,-113.9059"
          />
          <path
            id="path16"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M 1487.01,210.172 H 1378.58 V 175.629 H 1436 L 1435.72,3.01172 h 41.9 V 175.629 h 54.86 l 4.14,34.543 h -49.61"
          />
          <path
            id="path18"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M 1172.32,260.699 H 1024.19 V 3.01172 h 41.89 V 226.078 h 83.65 V 3.01172 h 41.91 V 260.699 h -19.32"
          />
          <path
            id="path20"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1905.12,324.574 62.44,113.946 3.92,-134.422 h 41.39 l -9.24,207.254 h -34.33 l -64.18,-121.192 -64.2,121.192 h -34.34 l -9.2,-207.254 h 41.35 l 4.04,134.422 62.35,-113.946"
          />
          <path
            id="path22"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1307.73,304.332 h 44.85 l -67.44,207.137 h -51.23 l -67.54,-207.137 h 44.98 l 11.37,40.84 h 73.67 z m -76.08,72.754 27.84,100.312 28,-100.312 h -55.84"
          />
          <path
            id="path24"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1728.22,304.098 v 207.527 h 41.98 V 304.098 Z m 41.98,207.527 h -41.98 z m -59.36,-153.633 c -0.63,-4.121 -1.78,-8.34 -3.09,-12.265 -4.57,-12.325 -13.14,-23.243 -24.53,-29.883 -15.33,-8.906 -33.71,-9.844 -50.9,-11.153 -7.2,-0.312 -14.36,-0.593 -21.52,-0.593 h -51.52 v 207.527 h 41.96 V 443.07 c 3.04,0 6.01,0 8.97,-0.097 16.9,-0.352 34.4,-1.27 50.57,-6.035 12.89,-3.79 24.4,-8.075 34.01,-17.715 3.16,-3.164 5.93,-7.071 8.32,-10.907 8.59,-14.679 10.21,-33.73 7.73,-50.324 z m -52.87,41.836 c -13.73,9.863 -32.74,10.625 -49.38,11.231 -2.45,0.078 -4.9,0.078 -7.35,0.175 v -74.492 c 8.14,0.235 16.4,0 24.68,0.117 13.83,0.118 31.58,1.524 38.63,14.727 3.32,5.957 4.41,12.383 4.59,19.023 0.29,10.782 -1.54,22.219 -11.17,29.219"
          />
          <path
            id="path26"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1702.03,106.254 c -2.28,-3.758 -5.15,-7.7149 -8.22,-10.8204 -9.77,-9.707 -21.21,-13.9336 -34.08,-17.7148 -16.18,-4.7657 -33.81,-5.7618 -50.55,-6.211 -3.04,0 -5.98,0 -9.06,0 V 3.01172 h -41.86 V 210.57 h 31.86 v 0 h 19.53 c 7.25,0 14.41,-0.195 21.54,-0.789 17.27,-1.054 35.72,-2.14 50.92,-10.929 11.41,-6.758 19.92,-17.637 24.49,-29.981 1.45,-3.848 2.56,-8.144 3.22,-12.324 2.43,-16.492 0.81,-35.555 -7.79,-50.293 z m -33.87,37.734 c -0.27,6.692 -1.4,13.047 -4.66,19.043 -7.13,13.282 -24.77,14.649 -38.7,14.739 -8.22,0.066 -16.42,-0.09 -24.68,0 v -74.348 c 2.52,0.098 5,0.098 7.42,0.117 16.64,0.606 35.55,1.445 49.35,11.328 9.67,6.934 11.47,18.281 11.27,29.121"
          />
          <path
            id="path28"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1059.9,433.93 c 0.13,23.34 1.64,50.246 11.25,72.011 3.52,7.93 9.71,15.383 17.07,20.02 17.33,11.113 48.98,3.691 66.45,-2.109 0,11.718 0,23.378 0,35.097 -15.61,5.293 -33.01,8.926 -55.1,7.754 -51.8,-5.078 -74.13,-46.953 -80.04,-100.039 -1.17,-10.762 -1.83,-21.816 -1.89,-32.734 0.06,-10.867 0.84,-21.864 1.89,-32.735 5.91,-55.058 20.76,-97.265 78.91,-100.449 22.04,-1.055 40.62,2.852 56.23,8.203 0,11.77 0,23.449 0,35.098 -17.47,-5.859 -50.12,-13.457 -67.56,-2.43 -7.21,4.668 -12.89,12.254 -16.35,20.125 -9.58,21.699 -10.73,48.828 -10.86,72.188"
          />
          <path
            id="path30"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1861.74,106.859 c 0,-26.4449 -3.63,-43.0543 -10.06,-54.1402 -11.56,-19.8907 -31.35,-20.0469 -33.81,-20.0469 h -3.01 c -2.5,0 -22.26,0.1562 -33.75,20.0469 -6.46,11.0859 -10.06,27.6953 -10.06,54.1402 0,26.368 3.6,42.93 10.06,54.102 11.49,19.746 31.25,20.09 33.75,20.019 h 3.01 c 2.46,0.071 22.25,-0.273 33.81,-20.019 6.43,-11.172 10.06,-27.734 10.06,-54.102 z M 1817.6,0.0234375 h 0.53 c 4.7,-0.2031255 43.37,0.1171875 65.97,28.8007625 12.56,15.8594 19.67,39.8321 19.67,78.0348 0,38.086 -7.11,62.012 -19.67,77.911 -22.6,28.73 -61.27,29.035 -65.97,28.984 h -3.54 c -4.77,0.051 -43.4,-0.254 -66.02,-28.984 -12.48,-15.899 -19.57,-39.825 -19.57,-77.911 0,-38.2027 7.09,-62.1754 19.57,-78.0348 22.62,-28.683575 61.25,-29.003888 66.02,-28.8007625 h 3.01"
          />
          <path
            id="path32"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1949.24,210.172 -0.49,-36.106 c -0.39,-17.851 -1.78,-55.019 -5.02,-84.5504 -2.32,-22.1289 -5.02,-33.9258 -6.52,-37.4297 -3.19,-8.4882 -8.46,-13.164 -12.09,-15.8593 -4.38,-3.3125 -7.7,-3.3985 -11.37,-4.4844 l 3.67,-31.6797 9.42,0.742188 c 11.73,1.750002 26.19,7.550782 36.97,19.062512 10.29,10.8008 13.85,25.7734 15.9,40.1875 4.45,29.6172 6.44,70.3203 7.05,86.1793 l 1.21,29.395 h 57.05 V 3.01172 h 41.68 V 210.172 h -137.46"
          />
          <path
            id="path34"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1396.89,511.293 -0.53,-36.152 c -0.28,-17.871 -1.77,-55.137 -5.05,-84.461 -2.26,-22.297 -5,-33.996 -6.38,-37.473 -3.29,-8.535 -8.58,-13.23 -12.16,-15.859 -4.29,-3.34 -7.74,-3.399 -11.36,-4.481 l 3.75,-31.699 9.41,0.711 c 11.74,1.797 26.07,7.637 37.01,18.965 10.2,10.937 13.77,25.918 15.84,40.285 4.44,29.637 6.47,70.301 7.03,86.16 l 1.18,29.395 h 57.02 V 304.098 h 41.76 v 207.195 h -137.52"
          />
          <path
            id="path36"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 1264.52,94.2734 h 84.43 v 31.9216 h -84.43 v 49.434 h 92.1 l 4.02,34.543 H 1222.68 V 3.01172 h 137.96 V 37.6016 h -96.12 v 56.6718"
          />
          <path
            id="path38"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 2156.13,94.2734 h 84.46 v 31.9216 h -84.46 v 49.434 h 92.03 l 4.11,34.543 H 2114.22 V 3.01172 h 138.05 V 37.6016 h -96.14 v 56.6718"
          />
          <path
            id="path40"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 2330.9,51.957 c -3.24,-8.5234 -13.63,-13.0351 -18.67,-15.7304 -4.89,-2.5508 -7.78,-3.3985 -11.53,-4.4844 l 3.75,-31.6797 9.48,0.851563 c 5.5,0.390627 13.55,2.097657 18.86,3.933597 19.87,6.97264 29.94,24.51174 39.51,42.04294 2.04,3.7969 4.09,7.6446 5.83,11.5117 7.38,15.0118 14.37,31.7969 20.8,48.3127 l 40.66,103.457 h -45.33 L 2352.32,89.2422 2310.47,210.172 h -45.31 L 2330.9,51.957"
          />
          <path
            id="path42"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M 614.441,463.871 512.051,566.313 409.531,463.871 512.051,361.461 153.555,3.01172 H 358.418 L 819.355,463.871 716.855,566.313 614.441,463.871"
          />
          <path
            id="path44"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M 204.746,463.871 102.402,566.313 0,463.871 291.816,171.957 l 102.372,102.395 -87.047,87.109 102.39,102.41 -102.39,102.442 z M 527.363,141.207 424.949,38.8516 460.82,3.01172 H 665.645 L 527.363,141.207"
          />
          <path
            id="path46"
            fill="white"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="m 446.152,359.887 c 0,-20.242 -16.425,-36.602 -36.699,-36.66 -20.195,0 -36.543,16.418 -36.523,36.652 0,20.223 36.601,77.363 36.601,77.363 0,0 36.621,-57.031 36.621,-77.355"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

Logo.muiName = 'SvgIcon';

Logo = React.memo(Logo);
Logo.displayName = 'Logo';

const WhiteLogoRu = Logo;

export default WhiteLogoRu;
