import { createAsyncThunk } from '@reduxjs/toolkit';
import { AllowedLocale, GlossaryResponse } from '../../types';
import { get } from '../../Api';

export const fetchGlossary = createAsyncThunk(
  'fetchGlossary',
  (query: string, { getState }) =>
    get<GlossaryResponse>(
      `/${
        (getState() as { locale: AllowedLocale }).locale
      }/glossary/?query=${query}`
    ).then((response) => response.data.payload)
);
