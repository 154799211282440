import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { fetchDetailDocument } from './asyncAction';
import { DetailDocument } from '../../types';

interface DetailDocumentsState {
  byId: Record<string, DetailDocument>;
}

const initialState: DetailDocumentsState = {
  byId: {},
};

const detailDocumentsSlice = createSlice({
  name: 'detailDocuments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDetailDocument.fulfilled, (state, { payload }) => {
      if (!isEqual(payload, state.byId[payload.id])) {
        state.byId[payload.id] = payload;
      }
    });
  },
});

export const { actions } = detailDocumentsSlice;

export default detailDocumentsSlice.reducer;
