import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import { fetchGlossary } from '../../store/glossary';
import GlossaryList from '../GlossaryList';
import useStyles from './GlossaryContent.styles';
import LocalizedProperty from '../LocalizedProperty';
import TextNotFound from '../TextNotFound';
import localizationDictionary from '../../localizationDictionary';

const debouncedFetchGlossary = debounce(
  (query: string, dispatch: Dispatch<any>) => {
    dispatch(fetchGlossary(query));
  },
  1000
);

const GlossaryContent = () => {
  const classes = useStyles();
  const [query, setQuery] = useState<string>('');

  const dispatch = useDispatch();
  const glossary = useSelector((state) => state.glossary);
  const locale = useSelector((state) => state.locale);

  const handleQueryChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setQuery(event.target.value as string);
  };

  useEffect(() => {
    debouncedFetchGlossary(query, dispatch);
  }, [dispatch, query]);

  if (!glossary) return <LinearProgress />;

  return (
    <>
      <Grid container justify="space-between">
        <Grid container justify="center" item xs={5} className={classes.header}>
          <DialogTitle className={classes.typography}>
            <LocalizedProperty propertyName="glossary" />
          </DialogTitle>
        </Grid>
        <Grid item xs={7} container alignItems="center">
          <TextField
            className={classes.searchInput}
            label={localizationDictionary[locale].search}
            onChange={handleQueryChange}
            value={query}
            variant="outlined"
            InputProps={{
              endAdornment:
                query.length > 0 ? (
                  <InputAdornment position="end" onClick={() => setQuery('')}>
                    <ClearIcon />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
            }}
            size="small"
          />
        </Grid>
      </Grid>
      {glossary.length === 0 ? (
        <TextNotFound />
      ) : (
        <GlossaryList glossary={glossary} />
      )}
    </>
  );
};

export default GlossaryContent;
