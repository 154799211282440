import React, { useEffect } from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import { fetchDetailDocument } from '../../store/detailDocuments';
import CardContent from '../CardContent';
import ContentSkeleton from '../ContentSkeleton';

interface CardDrawerProps {
  id: string;
  onClose: () => void;
}

const CardDrawer = ({ id, onClose }: CardDrawerProps) => {
  const dispatch = useDispatch();

  const detailDocument = useSelector((state) => state.detailDocuments.byId[id]);

  useEffect(() => {
    dispatch(fetchDetailDocument(id));
  }, [id, dispatch]);

  return (
    <MuiDrawer variant="temporary" open onClose={onClose} anchor="right">
      {detailDocument ? (
        <CardContent {...detailDocument} />
      ) : (
        <ContentSkeleton />
      )}
    </MuiDrawer>
  );
};

export default CardDrawer;
