import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './GlossaryList.styles';
import { GlossaryItem } from '../../types';

interface GlossaryProps {
  glossary: GlossaryItem[];
}

const GlossaryList = ({ glossary }: GlossaryProps) => {
  const classes = useStyles();

  return (
    <>
      {glossary.map(({ id, key, value }: GlossaryItem) => (
        <Grid container key={id}>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            item
            xs={5}
            className={classes.key}
          >
            <Typography variant="h6" align="right">
              {key}
            </Typography>
          </Grid>
          <Grid item xs={7} className={classes.value}>
            <Typography>{value}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default GlossaryList;
