import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      width: 150,
      color: 'inherit',
    },
  })
);
