import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        color: '#fff',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.87)',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: '#fff',
      },
    },
  },
});
