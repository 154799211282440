import React, { ChangeEvent } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ControllerProps } from 'react-hook-form';
import { MultiSelect } from '../../types';

export default ({
  options,
  name,
  value,
  onChange,
}: MultiSelect & ControllerProps<'select'>) => {
  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    onChange && onChange(event as ChangeEvent<HTMLSelectElement>);
  };

  return (
    <Select fullWidth name={name} value={value} onChange={handleChange}>
      {options.map(({ value: optionValue, label }) => (
        <MenuItem key={optionValue} value={optionValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
