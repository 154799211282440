import React from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Select, DatePicker, Field } from '../../types';
import SelectComponent from '../Select';
import DatePickerComponent from '../DatePicker';
import useStyles from './FilterField.styles';

type FilterFieldProps = Field & {
  control: any;
};

const FilterField = ({
  type,
  name,
  control,
  label,
  ...rest
}: FilterFieldProps) => {
  let controllerProps: any;

  const classes = useStyles();

  switch (type) {
    case 'select':
      controllerProps = {
        as: SelectComponent,
        ...(rest as Select),
      };
      break;
    case 'date':
      controllerProps = {
        as: DatePickerComponent,
        ...(rest as DatePicker),
      };
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={3}
      className={classes.root}
    >
      <Grid item xs={4} container justify="flex-end">
        <Typography variant="body1" align="right">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Controller
          {...controllerProps}
          fullWidth
          name={name}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FilterField;
