import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import objectHash from 'object-hash';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import FilterField from '../FilterField';
import LocalizedProperty from '../LocalizedProperty';
import useStyles from './FilterForm.styles';
import getDefaultValues from './getDefaultValues';
import { FilterState } from '../../types';
import { fetchTree } from '../../store/tree';
import { filterStateActions } from '../../store/filterState';

const FilterForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);
  const filterState = useSelector((state) => state.filterState);

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: filterState || undefined,
  });

  const values = watch();
  const valuesHash = objectHash(values);

  useEffect(() => {
    dispatch(filterStateActions.set(values));
  }, [valuesHash]);

  const onSubmit = (data: FilterState | null) => {
    dispatch(fetchTree(data));
  };

  const handleReset = () => {
    reset(getDefaultValues(filters));
    dispatch(fetchTree());
  };

  if (!filters) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      {filters.map(({ ...fieldProps }, index) => (
        <FilterField key={index} {...fieldProps} control={control} />
      ))}
      <Box mt={2}>
        <Divider />
      </Box>
      <Grid container justify="flex-end">
        <Box m={2}>
          <Button variant="outlined" color="primary" type="submit">
            <LocalizedProperty propertyName="find" />
          </Button>
        </Box>
        <Box m={2} mr={0}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            <LocalizedProperty propertyName="reset" />
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

export default FilterForm;
