import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DocumentPropertyGroupViewStyle } from '../../types';

export default (viewStyle: DocumentPropertyGroupViewStyle) =>
  makeStyles(() =>
    createStyles({
      root: {
        ...viewStyle,
      },
    })
  );
