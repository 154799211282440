import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Employee } from '../../../types';

interface FormatEmployeeProps {
  employee: Employee;
}

const FormatEmployee = ({
  employee: { jobTitle, name },
}: FormatEmployeeProps) => (
  <Grid container item xs spacing={1}>
    <Grid item xs={6}>{`${jobTitle}`}</Grid>
    <Grid item xs={6}>{`${name}`}</Grid>
  </Grid>
);

export default FormatEmployee;
