import React from 'react';
import { Employee } from '../../../types';
import FormatEmployee from './FormatEmployee';

interface FormatEmployeesProps {
  employees: Employee[];
}

const FormatEmployees = ({ employees }: FormatEmployeesProps) => (
  <>
    {employees.map((employee, index) => (
      <FormatEmployee key={index} employee={employee} />
    ))}
  </>
);

export default FormatEmployees;
