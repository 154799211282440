import { createSlice } from '@reduxjs/toolkit';
import { fetchFilters } from './asyncAction';
import { Field } from '../../types';

const filtersSlice = createSlice({
  name: 'filters',
  initialState: null as Field[] | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => payload);
  },
});

export default filtersSlice.reducer;
