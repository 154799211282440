import { createSlice } from '@reduxjs/toolkit';
import { fetchGlossary } from './asyncAction';
import { GlossaryItem } from '../../types';

const glossarySlice = createSlice({
  name: 'detailDocuments',
  initialState: null as GlossaryItem[] | null,
  reducers: {
    reset: () => null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlossary.fulfilled, (state, { payload }) => payload);
  },
});

export const glossaryActions = glossarySlice.actions;
export default glossarySlice.reducer;
