import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const ContentSkeleton = () => (
  <Box pt={1} width="700px">
    <Skeleton variant="text" />
    <Box mb={1}>
      <Skeleton variant="rect" height="50px" />
    </Box>
    <Skeleton variant="rect" height="50px" />
    <Box mb={1}>
      <Skeleton variant="rect" height="300px" />
    </Box>
    <Box mb={1}>
      <Skeleton variant="rect" height="300px" />
    </Box>
  </Box>
);

export default ContentSkeleton;
