import { AllowedFieldType, Field } from '../../types';

export default function getDefaultValues(fields: Field[] | null) {
  if (!fields) return undefined;

  return fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.name]: getDefaultValueByFieldType(field.type),
    };
  }, {});
}

function getDefaultValueByFieldType(fieldType: AllowedFieldType) {
  switch (fieldType) {
    case 'select':
      return '';
    case 'date':
    default:
      return null;
  }
}
