import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { DocumentProperty } from '../../types';
import getPrintPropertyValue from './getPrintPropertyValue';

interface PropertyProps {
  property: DocumentProperty;
}

const Property = ({ property }: PropertyProps) => {
  const { key } = property;
  const printValue = getPrintPropertyValue(property);

  return (
    <Box mt={2} mb={2}>
      <Grid container alignItems="flex-start" item xs>
        <Grid item xs={4}>{`${key}:`}</Grid>
        <Grid item xs={8}>
          {printValue}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Property;
