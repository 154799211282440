import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    key: {
      background: '#3c0144',
      color: '#fff',
      padding: theme.spacing(0, 1),
    },
    value: {
      borderTop: '1px dotted #008c96',
      padding: theme.spacing(1, 0, 1, 1),
    },
  })
);
