import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LocalizedProperty from '../LocalizedProperty';
import useStyles from './TextNotFound.styles';

const TextNotFound = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={5} className={classes.grid} />
      <Grid container item justify="center" xs={7}>
        <Typography variant="h4">
          <LocalizedProperty propertyName="term" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TextNotFound;
