import React from 'react';
import { useSelector } from '../../store';
import dictionary, { AllowedProperty } from '../../localizationDictionary';

interface LocalizedPropertyProps {
  propertyName: AllowedProperty;
}

const LocalizedProperty = ({ propertyName }: LocalizedPropertyProps) => {
  const locale = useSelector((state) => state.locale);
  const property = dictionary[locale][propertyName];

  if (!property) return null;
  if (typeof property === 'string') return <>{property}</>;

  return property;
};

export default LocalizedProperty;
