import React, { PropsWithChildren } from 'react';
import AppBar from '../AppBar';
import useStyles from './Template.styles';

interface OtherProps {}

type TemplateProps = PropsWithChildren<OtherProps>;

const Template = ({ children }: TemplateProps) => {
  const classes = useStyles();
  return (
    <>
      <AppBar />
      <main className={classes.main}>{children}</main>
    </>
  );
};

export default Template;
