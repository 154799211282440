import { createSlice } from '@reduxjs/toolkit';
import { fetchLocale, changeLocale } from './asyncActions';
import { AllowedLocale } from '../../types';

const localeSlice = createSlice({
  name: 'locale',
  initialState: 'ru' as AllowedLocale,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchLocale.fulfilled,
      (state, { payload }) => payload.payload.locale
    );
    builder.addCase(changeLocale.fulfilled, (state, { payload }) =>
      payload.isSuccess ? payload.locale : state
    );
  },
});

export default localeSlice.reducer;
