import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '50px',
      width: '180px',
    },
  })
);

let Logo: any = (props: any) => {
  return (
    <SvgIcon
      className={useStyles().root}
      viewBox="0 0 270 58"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 249 37 L 271.96875 37 L 271.96875 58.878906 L 249 58.878906 Z M 249 37 " />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 199 37 L 212 37 L 212 58.878906 L 199 58.878906 Z M 199 37 " />
        </clipPath>
        <clipPath id="clip3">
          <path d="M 141 37 L 158 37 L 158 58.878906 L 141 58.878906 Z M 141 37 " />
        </clipPath>
        <clipPath id="clip4">
          <path d="M 230 37 L 248 37 L 248 58.878906 L 230 58.878906 Z M 230 37 " />
        </clipPath>
        <clipPath id="clip5">
          <path d="M 106 31 L 123 31 L 123 58.878906 L 106 58.878906 Z M 106 31 " />
        </clipPath>
        <clipPath id="clip6">
          <path d="M 159 36 L 177 36 L 177 58.878906 L 159 58.878906 Z M 159 36 " />
        </clipPath>
        <clipPath id="clip7">
          <path d="M 178 36 L 197 36 L 197 58.878906 L 178 58.878906 Z M 178 36 " />
        </clipPath>
        <clipPath id="clip8">
          <path d="M 125 37 L 140 37 L 140 58.878906 L 125 58.878906 Z M 125 37 " />
        </clipPath>
        <clipPath id="clip9">
          <path d="M 213 37 L 229 37 L 229 58.878906 L 213 58.878906 Z M 213 37 " />
        </clipPath>
        <clipPath id="clip10">
          <path d="M 15 0 L 86 0 L 86 58.878906 L 15 58.878906 Z M 15 0 " />
        </clipPath>
        <clipPath id="clip11">
          <path d="M 0 0 L 70 0 L 70 58.878906 L 0 58.878906 Z M 0 0 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <g clipPath="url(#clip1)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 267.433594 37.019531 L 260.761719 49.609375 L 254.089844 37.019531 L 250.523438 37.019531 L 249.558594 58.558594 L 253.867188 58.558594 L 254.273438 44.574219 L 260.761719 56.425781 L 267.25 44.574219 L 267.660156 58.558594 L 271.96875 58.558594 L 271 37.019531 "
          />
        </g>
        <g clipPath="url(#clip2)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 199.140625 37.019531 L 199.140625 58.558594 L 211.550781 58.558594 L 211.976563 54.960938 L 203.496094 54.960938 L 203.496094 37.019531 "
          />
        </g>
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 119.527344 0.980469 L 119.527344 4.621094 C 117.109375 3.679688 111.984375 2.632813 110.765625 5.363281 C 110.226563 6.558594 110.464844 8.367188 111.429688 9.296875 C 113.464844 11.253906 116.390625 12.3125 118.414063 14.273438 C 119.507813 15.507813 120.363281 16.984375 120.566406 19.105469 C 120.738281 23.210938 119.410156 25.820313 116.554688 26.902344 C 113.847656 28.121094 109.128906 27.6875 106.453125 26.753906 L 106.453125 22.964844 C 109.261719 23.824219 114.484375 25 115.886719 22.074219 C 116.21875 21.183594 116.316406 20.167969 116.132813 19.230469 C 115.679688 16.953125 113.40625 15.894531 111.632813 14.804688 C 110.019531 13.804688 108.441406 12.8125 107.351563 11.210938 C 106.722656 10.292969 106.300781 9.082031 106.230469 7.960938 C 106.058594 5.039063 106.96875 3.203125 108.386719 1.871094 C 109.648438 0.878906 111.261719 0.238281 113.289063 0.0117188 C 115.777344 -0.078125 117.832031 0.273438 119.527344 0.980469 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 183.859375 25.132813 L 190.347656 13.285156 L 190.753906 27.269531 L 195.0625 27.269531 L 194.097656 5.726563 L 190.53125 5.726563 L 183.859375 18.320313 L 177.1875 5.726563 L 173.621094 5.726563 L 172.65625 27.269531 L 176.964844 27.269531 L 177.375 13.285156 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 171.929688 5.726563 L 167.175781 5.726563 L 162.03125 15.546875 L 156.882813 5.726563 L 152.128906 5.726563 L 159.851563 19.144531 L 159.851563 27.269531 L 164.203125 27.269531 L 164.203125 19.144531 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 127.972656 19.695313 L 130.871094 9.269531 L 133.773438 19.695313 Z M 135.878906 27.269531 L 140.550781 27.269531 L 133.535156 5.726563 L 128.207031 5.726563 L 121.195313 27.269531 L 125.863281 27.269531 L 127.046875 23.015625 L 134.695313 23.015625 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 146.867188 23.667969 L 146.867188 5.730469 L 142.511719 5.730469 L 142.511719 27.269531 L 154.925781 27.269531 L 155.347656 23.667969 "
        />
        <g clipPath="url(#clip3)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 152.503906 37.019531 L 141.246094 37.019531 L 141.246094 40.617188 L 147.199219 40.617188 L 147.183594 58.558594 L 151.535156 58.558594 L 151.535156 40.617188 L 157.242188 40.617188 L 157.664063 37.019531 "
          />
        </g>
        <g clipPath="url(#clip4)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 239.1875 58.878906 C 239.878906 58.890625 240.589844 58.789063 241.261719 58.683594 C 244.90625 58.121094 246.265625 56.371094 246.828125 53.746094 C 247.144531 52.277344 247.152344 50.804688 247.148438 49.316406 C 247.148438 49.132813 247.152344 37.019531 247.152344 37.019531 L 242.789063 37.019531 L 242.789063 50.230469 C 242.789063 51.050781 242.765625 51.855469 242.632813 52.664063 C 242.371094 54.300781 241.394531 55.105469 240.007813 55.375 C 239.71875 55.433594 239.414063 55.480469 239.117188 55.484375 L 238.609375 55.484375 C 238.308594 55.480469 238.007813 55.433594 237.714844 55.375 C 236.328125 55.105469 235.355469 54.300781 235.089844 52.664063 C 234.957031 51.855469 234.9375 51.050781 234.9375 50.230469 L 234.9375 37.019531 L 230.574219 37.019531 C 230.574219 37.019531 230.578125 49.132813 230.574219 49.316406 C 230.574219 50.804688 230.578125 52.277344 230.894531 53.746094 C 231.457031 56.371094 232.820313 58.121094 236.460938 58.683594 C 237.136719 58.789063 237.84375 58.890625 238.539063 58.878906 "
          />
        </g>
        <g clipPath="url(#clip5)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 118.003906 41.210938 C 117.996094 41.726563 117.953125 42.246094 117.859375 42.753906 C 117.691406 43.691406 117.316406 44.597656 116.648438 45.300781 C 115.972656 46.011719 114.976563 46.378906 114.03125 46.582031 C 112.988281 46.804688 111.910156 46.894531 110.847656 46.867188 L 110.808594 46.867188 L 110.808594 35.210938 L 111.5 35.210938 C 112.558594 35.191406 113.632813 35.195313 114.671875 35.339844 C 115.023438 35.386719 115.371094 35.464844 115.699219 35.601563 C 116.074219 35.757813 116.457031 36.011719 116.730469 36.308594 C 116.992188 36.585938 117.265625 36.941406 117.425781 37.289063 C 117.738281 37.949219 117.871094 38.667969 117.941406 39.386719 C 117.996094 39.996094 118.011719 40.605469 118.003906 41.210938 M 121.289063 35.371094 C 120.242188 33.320313 118.027344 32.359375 115.851563 31.976563 C 114.871094 31.804688 113.890625 31.691406 112.894531 31.699219 L 106.453125 31.699219 L 106.453125 58.539063 L 110.808594 58.539063 L 110.808594 50.191406 C 119.070313 50.054688 120.539063 47.34375 121.359375 45.816406 C 121.664063 45.25 121.867188 44.589844 122.027344 43.972656 C 122.199219 43.308594 122.3125 42.625 122.367188 41.941406 C 122.539063 39.726563 122.316406 37.371094 121.289063 35.371094 "
          />
        </g>
        <g clipPath="url(#clip6)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 164.273438 40.378906 C 165.101563 40.398438 165.925781 40.421875 166.75 40.425781 C 167.578125 40.425781 168.421875 40.425781 169.238281 40.570313 C 169.699219 40.652344 170.152344 40.785156 170.574219 40.984375 C 171.453125 41.398438 171.875 42.253906 171.875 43.15625 C 171.878906 43.785156 171.769531 44.457031 171.429688 45.003906 C 170.664063 46.230469 168.960938 46.582031 167.597656 46.71875 C 166.492188 46.835938 165.382813 46.863281 164.273438 46.867188 Z M 174.308594 52.359375 C 174.308594 52.359375 173.523438 50.082031 171.84375 49.070313 C 173.398438 48.550781 174.769531 47.769531 175.46875 46.59375 C 176.152344 45.453125 176.203125 43.992188 176.199219 42.703125 C 176.195313 40.847656 175.308594 38.96875 173.464844 38.121094 C 172.574219 37.710938 171.617188 37.441406 170.644531 37.273438 C 168.925781 36.964844 167.164063 36.976563 165.421875 36.96875 C 164.746094 36.964844 164.035156 36.964844 163.296875 36.960938 L 159.917969 36.964844 L 159.917969 58.539063 L 164.273438 58.539063 L 164.273438 50.1875 C 165.34375 50.132813 166.402344 50.054688 167.421875 49.953125 C 167.628906 49.929688 167.839844 49.90625 168.054688 49.878906 C 168.226563 50.058594 168.390625 50.246094 168.546875 50.441406 C 169.039063 51.066406 169.460938 51.761719 169.75 52.503906 C 169.945313 52.988281 170.132813 53.511719 170.273438 54.015625 C 170.589844 55.121094 171.226563 57.292969 171.226563 57.292969 L 171.609375 58.539063 L 176.652344 58.539063 "
          />
        </g>
        <g clipPath="url(#clip7)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 187.285156 58.878906 L 187.332031 58.878906 C 187.824219 58.886719 191.84375 58.855469 194.191406 55.875 C 195.492188 54.222656 196.230469 51.730469 196.230469 47.765625 C 196.230469 43.804688 195.492188 41.3125 194.191406 39.660156 C 191.84375 36.679688 187.824219 36.648438 187.332031 36.65625 L 186.964844 36.65625 C 186.472656 36.648438 182.453125 36.679688 180.109375 39.660156 C 178.808594 41.3125 178.070313 43.804688 178.070313 47.765625 C 178.070313 51.730469 178.808594 54.222656 180.109375 55.875 C 182.453125 58.855469 186.472656 58.886719 186.964844 58.878906 Z M 191.863281 47.765625 C 191.863281 50.515625 191.488281 52.246094 190.820313 53.386719 C 189.617188 55.457031 187.5625 55.476563 187.308594 55.472656 L 186.992188 55.472656 C 186.742188 55.476563 184.679688 55.457031 183.480469 53.386719 C 182.8125 52.246094 182.433594 50.515625 182.433594 47.765625 C 182.433594 45.019531 182.8125 43.292969 183.480469 42.148438 C 184.679688 40.078125 186.742188 40.058594 186.992188 40.066406 L 187.308594 40.066406 C 187.5625 40.058594 189.617188 40.078125 190.820313 42.148438 C 191.488281 43.292969 191.863281 45.019531 191.863281 47.765625 "
          />
        </g>
        <g clipPath="url(#clip8)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 129.582031 49.070313 L 138.363281 49.070313 L 138.363281 45.75 L 129.582031 45.75 L 129.582031 40.621094 L 139.148438 40.621094 L 139.570313 37.019531 L 125.226563 37.019531 L 125.226563 58.558594 L 139.570313 58.558594 L 139.570313 54.960938 L 129.582031 54.960938 "
          />
        </g>
        <g clipPath="url(#clip9)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="evenodd"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 218.28125 49.070313 L 227.0625 49.070313 L 227.0625 45.75 L 218.28125 45.75 L 218.28125 40.621094 L 227.847656 40.621094 L 228.269531 37.019531 L 213.925781 37.019531 L 213.925781 58.558594 L 228.269531 58.558594 L 228.269531 54.960938 L 218.28125 54.960938 "
          />
        </g>
        <g clipPath="url(#clip10)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 63.871094 10.660156 L 53.226563 0.0117188 L 42.582031 10.660156 L 53.226563 21.304688 L 15.96875 58.5625 L 37.257813 58.5625 L 85.160156 10.660156 L 74.515625 0.0117188 "
          />
        </g>
        <g clipPath="url(#clip11)" clipRule="nonzero">
          <path
            stroke="none"
            fillRule="nonzero"
            fill="rgb(100%,100%,100%)"
            fillOpacity="1"
            d="M 54.824219 44.191406 L 44.175781 54.835938 L 47.90625 58.5625 L 69.195313 58.5625 Z M 21.289063 10.660156 L 10.644531 0.0117188 L 0 10.660156 L 30.339844 41 L 40.984375 30.351563 L 31.933594 21.304688 L 42.582031 10.660156 L 31.933594 0.0117188 "
          />
        </g>
        <path
          stroke="none"
          fillRule="evenodd"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 46.382813 21.460938 C 46.382813 23.566406 44.675781 25.269531 42.570313 25.273438 C 40.46875 25.273438 38.765625 23.570313 38.769531 21.464844 C 38.769531 19.363281 42.578125 13.425781 42.578125 13.425781 C 42.578125 13.425781 46.382813 19.359375 46.382813 21.460938 "
        />
      </g>
    </SvgIcon>
  );
};

Logo.muiName = 'SvgIcon';

Logo = React.memo(Logo);
Logo.displayName = 'Logo';

const WhiteLogoEn = Logo;

export default WhiteLogoEn;
