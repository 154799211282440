import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      height: 300,
      minWidth: 585,
    },
    grid: {
      background: '#3c0144',
    },
  })
);
