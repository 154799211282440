import { createSlice } from '@reduxjs/toolkit';
import { fetchTree } from './asyncActions';
import { DocumentNode } from '../../types';

const treeSlice = createSlice({
  name: 'tree',
  initialState: null as DocumentNode | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchTree.fulfilled,
      (state, { payload }) => payload.payload
    );
  },
});

export default treeSlice.reducer;
