import React from 'react';
import { DocumentProperty, Employee, Remission } from '../../types';
import FormatEmployee from './formatComponents/FormatEmployee';
import FormatEmployees from './formatComponents/FormatEmployees';
import FormatRemissions from './formatComponents/FormatRemissions';
import FormatString from './formatComponents/FormatString';

const getPrintPropertyValue = ({
  type,
  value,
}: Exclude<DocumentProperty, 'key'>) => {
  switch (type) {
    case 'string':
      return <FormatString string={value as string} />;
    case 'employee':
      return <FormatEmployee employee={value as Employee} />;
    case 'employees':
      return <FormatEmployees employees={value as Employee[]} />;
    case 'approveDate':
      return <FormatRemissions remissions={value as Remission[]} />;
    default:
      throw new Error(`Не удалось определить тип свойства. Прилетело: ${type}`);
  }
};

export default getPrintPropertyValue;
