import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import Property from '../Property';
import PropertyGroup from '../PropertyGroup';
import { DetailDocument } from '../../types';
import LocalizedProperty from '../LocalizedProperty';
import useStyles from './CardContent.styles';

const CardContent = (detailDocument: DetailDocument) => {
  const classes = useStyles(detailDocument.themeColor)();

  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.heading, classes.innerSpacing)}>
        <Typography variant="subtitle2">
          <LocalizedProperty propertyName="registrationNumber" />
          <span>:</span>
        </Typography>
        <Typography variant="h5">
          {detailDocument.registrationNumber}
        </Typography>
      </Box>
      <Typography variant="h5">
        <Box className={clsx(classes.innerSpacing, classes.title)}>
          {detailDocument.title}
          <Link
            href={detailDocument.link.to}
            target="_blank"
            variant="body1"
            color="secondary"
            component={({ children, ...rest }) => (
              <Box display="flex" alignItems="center" {...rest} component="a">
                {children}
              </Box>
            )}
          >
            Ссылка на документ
            <LaunchIcon fontSize="inherit" />
          </Link>
        </Box>
      </Typography>
      {detailDocument.propertyGroups.map(({ properties, viewStyle }, index) => (
        <PropertyGroup key={index} viewStyle={viewStyle}>
          {properties.map((property, propertyIndex) => (
            <Property key={propertyIndex} property={property} />
          ))}
        </PropertyGroup>
      ))}
    </Box>
  );
};

export default CardContent;
