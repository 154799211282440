import { createStyles, makeStyles } from '@material-ui/core/styles';

export default (backgroundColor: string) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        width: 700,
      },
      innerSpacing: {
        padding: theme.spacing(2, 2, 2, 6),
      },
      heading: {
        backgroundColor,
      },
      title: {
        fontWeight: 'bold',
      },
    })
  );
