import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFilters } from '../filters';
import { FilterState } from '../../types';
import getDefaultValues from '../../components/FilterForm/getDefaultValues';

const filterStateSlice = createSlice({
  name: 'filterState',
  initialState: null as FilterState | null,
  reducers: {
    set: (state, { payload }: PayloadAction<FilterState | null>) => payload,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => {
      if (!state) {
        return getDefaultValues(payload);
      }
      return state;
    });
  },
});

export const filterStateActions = filterStateSlice.actions;
export default filterStateSlice.reducer;
