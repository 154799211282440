// eslint-disable-next-line import/no-duplicates
import { isValid, format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ru from 'date-fns/locale/ru';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';

const parseAndFormatDate = (date: string, formatAs?: 'date' | 'dateTime') => {
  const dateObj = new Date(date);

  if (!isValid(dateObj)) return null;

  return format(
    dateObj,
    formatAs === 'dateTime' ? DATE_TIME_FORMAT : DATE_FORMAT,
    { locale: ru }
  );
};

export default parseAndFormatDate;
