import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalizedProperty from '../LocalizedProperty';
import { fetchFilters } from '../../store/filters';
import FilterForm from '../FilterForm';
import useStyles from './FilterDialog.styles';
import { useSelector } from '../../store';

interface FilterContentProps {
  opened: boolean;
  onClose: () => void;
}

const FilterDialog = ({ onClose, opened }: FilterContentProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const locale = useSelector((state) => state.locale);

  useEffect(() => {
    dispatch(fetchFilters());
  }, [locale, dispatch]);

  return (
    <Dialog open={opened} onClose={onClose}>
      <DialogTitle className={classes.header}>
        <LocalizedProperty propertyName="filterCriteria" />
      </DialogTitle>
      <DialogContent>
        <FilterForm />
      </DialogContent>
    </Dialog>
  );
};

export default FilterDialog;
