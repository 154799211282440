import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GlossaryDialog from '../GlossaryDialog';

const GlossaryButton = () => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <>
      <Box mr={2}>
        <IconButton
          onClick={() => {
            setOpened(true);
          }}
        >
          <MenuBookIcon htmlColor="white" />
        </IconButton>
      </Box>
      {opened && (
        <GlossaryDialog onClose={() => setOpened(false)} opened={opened} />
      )}
    </>
  );
};

export default GlossaryButton;
