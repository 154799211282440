import { DocumentNode, ChartNode } from '../../types';

const convertNode = (
  { id, title, description, childrenCount, children }: DocumentNode,
  hasParent: boolean
): ChartNode => ({
  id,
  person: {
    id,
    name: title,
    department: description,
    totalReports: childrenCount,
  },
  hasChild: !!children,
  hasParent,
  children: Array.isArray(children)
    ? children.map((child) => convertNode(child, true))
    : undefined,
});

export default convertNode;
