import React, { ChangeEvent } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ThemeProvider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSelector, changeLocaleAndReloadTree } from '../../store';
import useStyles from './LanguageSelector.styles';
import { AllowedLocale } from '../../types';
import theme from './LanguageSelector.theme';

const LanguageSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentLanguage = useSelector((state) => state.locale);

  const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch(changeLocaleAndReloadTree(event.target.value as AllowedLocale));
  };

  return (
    <ThemeProvider theme={theme}>
      <Select
        value={currentLanguage}
        onChange={onChange}
        className={classes.selector}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ru">Русский</MenuItem>
      </Select>
    </ThemeProvider>
  );
};

export default LanguageSelector;
