import React, { ReactElement } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ru as ruLocale, enUS as enLocale } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { ControllerProps } from 'react-hook-form';
import { DatePicker } from '../../types';
import { useSelector } from '../../store';
import { DATE_FORMAT } from '../../utils/parseAndFormatDate';
import LocalizedProperty from '../LocalizedProperty';

export default ({
  label,
  value,
  onChange,
}: DatePicker & ControllerProps<ReactElement>) => {
  const handleDateChange = (date: Date | null) => {
    onChange && onChange(date);
  };

  const locale = useSelector((state) => state.locale);
  const currentLanguage = () => (locale === 'ru' ? ruLocale : enLocale);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLanguage()}>
      <KeyboardDatePicker
        autoOk
        clearable
        clearLabel={<LocalizedProperty propertyName="clear" />}
        fullWidth
        label={label}
        format={DATE_FORMAT}
        value={value}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};
