import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { Remission } from '../../../types';
import parseAndFormatDate from '../../../utils/parseAndFormatDate';

const FormatRemission = ({ remissionNumber, date }: Remission) => {
  const formattedDate = parseAndFormatDate(date);

  if (!formattedDate) {
    console.error(`Не удалось преобразовать дату, прилетело: ${date}`);
    return null;
  }

  return (
    <MenuItem key={remissionNumber} value={remissionNumber}>
      <Typography variant="subtitle2">{`[${remissionNumber}] ${formattedDate}`}</Typography>
    </MenuItem>
  );
};

export default FormatRemission;
