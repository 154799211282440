import React, { useMemo, useRef, useState } from 'react';
import OrgChart from '@unicef/react-org-chart';
import objectHash from 'object-hash';
import './Chart.styles.css';
import convertNode from './nodeConverter';
import { useSelector } from '../../store';
import CardDrawer from '../CardDrawer';
import { DocumentNode } from '../../types';

const Chart = () => {
  const configRef = useRef({});
  const [documentId, setDocumentId] = useState<string | null>(null);

  const unconvertedTree = useSelector((state) => state.tree);
  const convertedTree = useMemo(
    () => unconvertedTree && convertNode(unconvertedTree, false),
    [unconvertedTree]
  );

  const onNodeClick = (node: DocumentNode) => {
    const { id } = node;
    setDocumentId(id);
  };

  const getChild = () => {
    return Promise.resolve(convertedTree);
  };

  const getParent = () => {
    return Promise.resolve(convertedTree);
  };

  if (!convertedTree) return null;

  return (
    <>
      <OrgChart
        key={objectHash(unconvertedTree)}
        tree={convertedTree}
        onNodeClick={(node: any) => {
          onNodeClick(node);
        }}
        loadImage={() => {
          return Promise.resolve();
        }}
        onConfigChange={(config: any) => {
          configRef.current = config;
        }}
        loadConfig={() => {
          return { ...configRef.current };
        }}
        loadChildren={() => {
          return getChild();
        }}
        loadParent={() => {
          return getParent();
        }}
      />
      {documentId && (
        <CardDrawer id={documentId} onClose={() => setDocumentId(null)} />
      )}
    </>
  );
};

export default Chart;
