import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Template from './components/Template';
import ChartTree from './components/Chart';
import { useSelector, initializeAppAndLoadTree } from './store';

const App = () => {
  const dispatch = useDispatch();
  const isAppInitialized = useSelector((state) => state.isAppInitialized);

  useEffect(() => {
    dispatch(initializeAppAndLoadTree());
  }, [dispatch]);

  if (!isAppInitialized) return <LinearProgress />;

  return (
    <>
      <Template>
        <ChartTree />
      </Template>
    </>
  );
};

export default App;
