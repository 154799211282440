import React, { ReactElement } from 'react';
import { AllowedLocale } from './types';
import WhiteLogoRu from './svg/WhiteLogoRu';
import WhiteLogoEn from './svg/WhiteLogoEn';

type LocalizationList = Record<AllowedProperty, string | ReactElement>;

export type AllowedProperty =
  | 'logo'
  | 'registrationNumber'
  | 'filterCriteria'
  | 'find'
  | 'reset'
  | 'glossary'
  | 'search'
  | 'term'
  | 'clear';

type LocalizedDictionary = Record<AllowedLocale, LocalizationList>;

export default {
  ru: {
    logo: <WhiteLogoRu />,
    registrationNumber: 'Регистрационный номер',
    filterCriteria: 'Критерии фильтрации',
    find: 'Найти',
    reset: 'Сброс',
    glossary: 'Глоссарий',
    search: 'Поиск',
    term: 'Термин не найден',
    clear: 'Очистить',
  },
  en: {
    logo: <WhiteLogoEn />,
    registrationNumber: 'Registration Number',
    filterCriteria: 'Filter criteria',
    find: 'Find',
    reset: 'Reset',
    glossary: 'Glossary',
    search: 'Search',
    term: 'Term is not found',
    clear: 'Clear',
  },
} as LocalizedDictionary;
