import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    header: {
      background: '#3c0144',
      color: '#fff',
    },
    content: {
      padding: 0,
    },
    typography: {
      textDecoration: 'underline',
    },
    searchInput: {
      width: '90%',
      margin: '0 auto',
    },
  })
);
