import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import GlossaryContent from '../GlossaryContent';
import { glossaryActions } from '../../store/glossary';

interface GlossaryComponentProps {
  opened: boolean;
  onClose: () => void;
}

const GlossaryDialog = ({ onClose, opened }: GlossaryComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(glossaryActions.reset());
    };
  }, [dispatch]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      onClose={onClose}
    >
      <GlossaryContent />
    </Dialog>
  );
};

export default GlossaryDialog;
