import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../Api';
import { AllowedLocale, DocumentsResponse, FilterState } from '../../types';

export const fetchTree = createAsyncThunk(
  'fetchTree',
  (filters: FilterState | null | undefined, { getState }) =>
    post<DocumentsResponse>(
      `/${(getState() as { locale: AllowedLocale }).locale}/documents/`,
      serializeFilterState(filters)
    ).then((response) => response.data)
);

function serializeFilterState(
  filters?: FilterState | null
): Record<string, string> | undefined {
  if (!filters) return undefined;

  return Object.entries(filters).reduce((acc, [type, value]) => {
    return {
      ...acc,
      [type]: getValue(type, value),
    };
  }, {});
}

function getValue(type: string, value: string | Date) {
  switch (type) {
    case 'approveDate':
      return value ? new Date(value).toISOString() : undefined;

    case 'status':
    case 'type':
    default:
      return value || undefined;
  }
}
