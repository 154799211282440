import { configureStore, combineReducers } from '@reduxjs/toolkit';
import localeReducer from './locale';
import isAppInitializedReducer from './isAppInitialized';
import treeReducer from './tree';
import detailDocumentsReducer from './detailDocuments';
import filtersReducer from './filters';
import filterStateReducer from './filterState';
import glossaryReducer from './glossary';

const reducer = combineReducers({
  locale: localeReducer,
  isAppInitialized: isAppInitializedReducer,
  tree: treeReducer,
  detailDocuments: detailDocumentsReducer,
  filters: filtersReducer,
  filterState: filterStateReducer,
  glossary: glossaryReducer,
});

const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;

export default store;
