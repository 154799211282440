import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '../../Api';
import { AllowedLocale, BaseServerResponse } from '../../types';

export const fetchLocale = createAsyncThunk('getLocale', () => {
  return get<BaseServerResponse<{ locale: AllowedLocale }>>(
    '/get-initial-app-data/'
  ).then((response) => response.data);
});

export const changeLocale = createAsyncThunk(
  'changeLocale',
  (locale: AllowedLocale) =>
    post<BaseServerResponse<boolean>>('/set-user-locale/', {
      locale,
    }).then((response) => ({ isSuccess: response.data.isSuccess, locale }))
);
