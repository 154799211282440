import React, { PropsWithChildren } from 'react';
import Box from '@material-ui/core/Box';
import { DocumentPropertyGroupViewStyle } from '../../types';
import useStyles from './PropertyGroup.styles';

interface OtherProps {
  viewStyle: DocumentPropertyGroupViewStyle;
}

type TemplateProps = PropsWithChildren<OtherProps>;

const PropertyGroup = ({ children, viewStyle }: TemplateProps) => {
  const classes = useStyles(viewStyle)();

  return (
    <Box p={2} pl={6} className={classes.root}>
      {children}
    </Box>
  );
};

export default PropertyGroup;
