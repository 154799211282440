import { createSlice } from '@reduxjs/toolkit';
import { fetchLocale } from '../locale';

const isAppInitialized = createSlice({
  name: 'isAppInitialized',
  initialState: false,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchLocale.fulfilled, () => true),
});

export default isAppInitialized.reducer;
