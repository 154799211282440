import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    header: {
      color: '#fff',
      background: '#3c0144',
    },
    main: {
      width: 1200,
    },
  })
);
