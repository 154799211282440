import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeLocale, fetchLocale } from './locale';
import { fetchTree } from './tree';
import { AllowedLocale } from '../types';

export const initializeAppAndLoadTree = createAsyncThunk(
  'initializeAppAndLoadTree',
  async (arg, { dispatch }) => {
    await dispatch(fetchLocale());
    await dispatch(fetchTree());
  }
);

export const changeLocaleAndReloadTree = createAsyncThunk(
  'changeLocaleAndReloadTree',
  async (locale: AllowedLocale, { dispatch }) => {
    await dispatch(changeLocale(locale));
    await dispatch(fetchTree());
  }
);
